// extracted by mini-css-extract-plugin
export const about = "AboutSection-module--about--M1llB";
export const innerArea = "AboutSection-module--innerArea--19aYd";
export const customShapes = "AboutSection-module--customShapes--1_dkI";
export const customShape1 = "AboutSection-module--customShape1--32P-B";
export const inner = "AboutSection-module--inner--ndaAS";
export const media = "AboutSection-module--media--7aaWW";
export const meta = "AboutSection-module--meta--14uT1";
export const manArea = "AboutSection-module--manArea--3ED5e";
export const manWrapper = "AboutSection-module--manWrapper--2Jhw7";
export const pulsateBtn = "AboutSection-module--pulsateBtn--31MMJ";
export const man = "AboutSection-module--man--25Wwg";
export const shape1 = "AboutSection-module--shape1--dYcaU";
export const shape2 = "AboutSection-module--shape2--3D4QJ";
export const title = "AboutSection-module--title--2mvzo";
export const desc = "AboutSection-module--desc--25Jbl";
export const quoteWrapper = "AboutSection-module--quoteWrapper--1_INo";
export const quote = "AboutSection-module--quote--2nto5";
export const lq = "AboutSection-module--lq--1T3qr";
export const rq = "AboutSection-module--rq--1xI0m";