import BgShape from '@/assets/img/bg-shape-up.svg';
import Line1 from '@/assets/img/NewsSection/line1.svg';
import Line2 from '@/assets/img/NewsSection/line2.svg';
import Shape1 from '@/assets/img/shape-lines.svg';

const data = {
  bgShapeSrc: BgShape,
  line1Src: Line1,
  line2Src: Line2,
  shape1Src: Shape1,
  title: 'СМИ о Нас',
  readMoreText: 'Читать далее',
  items: [
    {
      img: '1',
      title: 'Как кризис превратить в успех?',
      excerpt:
        'О том как отстроить жизнь заново, выйти на новый уровень эффективности и стать вдохновителем миллионов людей по всему миру рассказывает Андреас Тиссен.',
      href: 'https://interfax.com.ua/news/press-release/718123.html',
    },
    {
      img: '2',
      title: 'Кризис → Успех<br>Советы Андреаса Тиссена',
      excerpt: 'Андреас Тиссен о преуспевании во времена перемен.',
      href:
        'https://biz.liga.net/preview-article/kak-krizis-prevratit-v-uspeh-sovety-andreasa-tissena/3296d2',
    },
    {
      img: '3',
      title: 'Кризис: убытки или доходы?',
      excerpt:
        'Если ты не хочешь чтобы завтра было тоже что и вчера — развивайся! Несмотря на пандемию, охватившую мир, экономический кризис и повсеместную панику 2020 год…',
      href:
        'https://focus.ua/lifestyle/472527-krizis-ubytki-ili-dohody-andreas-tissen-o-preuspevanii-vo-vremena-peremen',
    },
    {
      img: '4',
      title: 'Время учиться. Время помогать. Время путешествовать.',
      excerpt:
        '«От хорошего к великому. Главное начать путь перемен. Перемены — это рост. Рост — это успех. А успех — это жизнь!» — именно этими словами начался в Танзании несколько дней…',
      href: 'https://interfax.com.ua/news/press-release/719107.html',
    },
    {
      img: '5',
      title: 'Никогда не поздно быть лучшим',
      excerpt:
        'В Доминикане в отеле Hard Rock прошёл юбилейный BaseCamp от мотивационного тренера Андреаса Тиссена. В инновационном обучающем интенсиве приняли участие…',
      href: 'https://interfax.com.ua/news/press-release/729562.html',
    },
    {
      img: '6',
      title: '3 причины прочитать новую книгу Андреаса Тиссена',
      excerpt:
        'Жить во времена перемен — это искусство. Где взять необходимые знания и энергию, рассказывает Андреас Тиссен в своей новой книге «Встань! Двигай задом! Живи».',
      href: 'https://interfax.com.ua/news/press-release/730243.html',
    },
  ],
};

export default data;
