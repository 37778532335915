import React from 'react';
import cx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './NewsSection.module.scss';
import data from './data';

const NewsSection = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { regex: "/NewsSection/content*/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 640
                placeholder: BLURRED
                formats: [JPG, WEBP, AVIF]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const imageItems = query.allFile.edges;
  const imageDataMapByName = imageItems.reduce(
    (
      acc,
      {
        node: {
          name,
          childImageSharp: { gatsbyImageData },
        },
      }
    ) => ({ ...acc, [name]: gatsbyImageData }),
    {}
  );
  const dataItemsWithImages = data.items.map((item) => ({
    ...item,
    img: imageDataMapByName[item.img],
  }));

  return (
    <section className={styles.news} id="news">
      <img className={styles.bgShape} src={data.bgShapeSrc} alt="" />
      <img className={styles.line1} src={data.line1Src} alt="" />
      <img className={styles.line2} src={data.line2Src} alt="" />
      <img className={styles.shape1} src={data.shape1Src} alt="" />
      <div
        className="container relative z-index-2"
        data-anim-aos="fade"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="row jc-c">
          <div className="col-12 col-lg-10">
            <h2
              className={cx('c-section-title', styles.title)}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div className="mt-6 mt-md-8">
              <div className="row mt-n6 mt-md-n8 mt-lg-n10 mt-xl-n11">
                {dataItemsWithImages.map((item, index) => (
                  <div
                    className="col-12 col-sm-6 mt-8 mt-lg-10 mt-xl-11"
                    key={`news-${index}`}
                  >
                    <div className={styles.item}>
                      <div className={styles.itemMedia}>
                        <a
                          className={styles.itemImgWrapper}
                          href={item.href}
                          title={data.readMoreText}
                          rel="nofollow noopener noreferrer"
                          target="_blank"
                        >
                          <GatsbyImage
                            className={styles.itemImg}
                            image={item.img}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className={styles.itemMeta}>
                        <h3
                          className={styles.itemTitle}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <div
                          className={styles.itemExcerpt}
                          dangerouslySetInnerHTML={{ __html: item.excerpt }}
                        />
                        <a
                          href={item.href}
                          rel="nofollow noopener noreferrer"
                          target="_blank"
                          className={styles.itemLink}
                        >
                          {data.readMoreText}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
