import Shape1 from '@/assets/img/IntroSection/shape1.svg';
import Shape2 from '@/assets/img/IntroSection/shape2.svg';
import ShapeBr from '@/assets/img/IntroSection/shape-br.svg';

const data = {
  shape1Src: Shape1,
  shape2Src: Shape2,
  shapeBrSrc: ShapeBr,
  titleLines: ['Андреас', 'Тиссен'],
  desc:
    'Ведущий европейский тренер по мотивации и эксперт по манерам поведения с опытом более 29 лет, бизнес-тренер, космополит, профессиональный спортсмен.',
};

export default data;
