/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import cx from 'clsx';
import * as styles from './YtPlayer.module.scss';

const YtPlayer = ({
  src = '5iqduvIg5ew',
  thumbUrl: thumbnailUrl,
  variant,
  className,
  children,
  ...props
}) => {
  const thumbUrl =
    thumbnailUrl ||
    // `https://i.ytimg.com/vi/${src}/maxresdefault.jpg` ||
    `https://i.ytimg.com/vi/${src}/hqdefault.jpg`;

  const imageRef = useRef(null);
  const onContentClick = (e) => {
    e.preventDefault();
    imageRef.current.click();
  };

  return (
    <a
      srl_video_thumbnail={thumbUrl}
      srl_video_controls="false"
      href={`https://www.youtube.com/watch?v=${src}`}
      className={cx(
        styles.wrapper,
        variant && styles[`is_${variant}`],
        className
      )}
      {...props}
    >
      {/** NOTE: Until be fixed https://github.com/michelecocuccio/simple-react-lightbox/issues/143 */}
      <img
        ref={imageRef}
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        height="100%"
        alt=""
        style={{ display: 'none' }}
      />
      <div
        onClick={onContentClick}
        className={styles.thumbnail}
        style={{ backgroundImage: `url('${thumbUrl}')` }}
      >
        <div className={styles.meta}>
          <div className={styles.playArea}>
            <button className={styles.playButton}>
              <svg
                className={cx('ic ic--yt-play-icon', styles.playIcon)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 24"
                width="100"
                height="75"
              >
                <path
                  fill="#e02f2f"
                  d="M31.67 5.18a7.69 7.69 0 0 0-1.27-3.39A4.41 4.41 0 0 0 27.19.35C22.72 0 16 0 16 0S9.27 0 4.8.35a4.41 4.41 0 0 0-3.21 1.44A7.71 7.71 0 0 0 .32 5.18 55 55 0 0 0 0 10.7v2.59a55 55 0 0 0 .32 5.52 7.68 7.68 0 0 0 1.27 3.39 5.23 5.23 0 0 0 3.53 1.46C7.68 23.92 16 24 16 24s6.72 0 11.2-.35a4.43 4.43 0 0 0 3.2-1.45 7.69 7.69 0 0 0 1.27-3.39 55 55 0 0 0 .33-5.52V10.7a55 55 0 0 0-.33-5.52z"
                ></path>
                <path fill="#fff" d="M12 6v12l10-6-10-6z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default YtPlayer;
