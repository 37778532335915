import Shape1 from '@/assets/img/shape-lines.svg';
import Shape2 from '@/assets/img/IntroSection/shape1.svg';
import Lq from '@/assets/img/AboutSection/lq.svg';
import Rq from '@/assets/img/AboutSection/rq.svg';

const data = {
  shape1Src: Shape1,
  customShapeSrc: Shape2,
  lqSrc: Lq,
  rqSrc: Rq,
  videoSrc: '8BPqO0rvEI4',
  titleLines: ['Кто такой', 'Андреас'],
  descParagraphs: [
    `Эксперт в области мышления и мотивации кадров. На сегодняшний день он провёл <strong>более 1000 тренингов</strong>, является автором и соавтором <strong>5 книг на 3 языках.</strong>`,
    `В 16 лет <strong>Андреас Тиссен</strong> эмигрировал из Казахстана в Германию, не зная немецкого языка, начал развиваться в предпринимательстве. В последствии прекрасно овладел немецким и не забыл русский. <strong>Основал холдинговую компанию с оборотом в 1,2 миллиарда евро.</strong> В 2011 году продал свою компанию, начал заниматься коучингом и проводить тренинги.`,
  ],
  quote: `Мой подход экономит людям время, помогает обрести целостное восприятие себя и ситуации. Это важно, когда ресурсов мало, а тревоги много. <strong>Я беру людей, в той точке личного развития, где они находятся сейчас и веду их в привлекательное будущее.</strong> То есть к новой версии себя, к развитию скрытого потенциала и использованию возможностей, которые содержаться в любом кризисе.`,
};

export default data;
