// extracted by mini-css-extract-plugin
export const intro = "IntroSection-module--intro--2_1A8";
export const back = "IntroSection-module--back--3UZgM";
export const backdropMan = "IntroSection-module--backdropMan--2RJS-";
export const front = "IntroSection-module--front--3XDCA";
export const frontManArea = "IntroSection-module--frontManArea--3jDlT";
export const frontMan = "IntroSection-module--frontMan--O2iG6";
export const customShapes = "IntroSection-module--customShapes--3s31f";
export const customShape1 = "IntroSection-module--customShape1--3YsAK";
export const customShape2 = "IntroSection-module--customShape2--1m-OJ";
export const circle = "IntroSection-module--circle--1OQ7Z";
export const line1 = "IntroSection-module--line1--1bSc0";
export const line2 = "IntroSection-module--line2--rlfFn";
export const meta = "IntroSection-module--meta--2-hmD";
export const title = "IntroSection-module--title--aWu8b";
export const desc = "IntroSection-module--desc--3oL_r";