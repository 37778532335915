import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import { GatsbyImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';
// import SimpleReactLightbox, { SRLWrapperCustom } from '@/components/Lightbox';
import data from './data';
import * as styles from './ReviewsSection.module.scss';
import YtPlayer from '@/components/YtPlayer/YtPlayer';

// import SimpleReactLightbox, { SRLWrapperCustom } from '@/components/Lightbox';

const LoadableSimpleReactLightbox = loadable(
  () => import('@/components/Lightbox'),
  {
    fallback: <div>Загрузка...</div>,
  }
);
const LoadableSRLWrapperCustom = loadable(
  () => import('@/components/Lightbox'),
  {
    resolveComponent: (components) => components.SRLWrapperCustom,
    fallback: <div>Загрузка...</div>,
  }
);

const ReviewsSection = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { regex: "/ReviewsSection/video-thumbs*/" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            publicURL
            # childImageSharp {
            #   gatsbyImageData(
            #     width: 640
            #     placeholder: BLURRED
            #     formats: [JPG, WEBP, AVIF]
            #     quality: 100
            #   )
            # }
          }
        }
      }
    }
  `);

  const imageItems = query.allFile.edges;
  const imageDataMapByName = imageItems.reduce(
    (
      acc,
      {
        node: {
          name,
          publicURL,
          // childImageSharp: { gatsbyImageData },
        },
      }
    ) => ({ ...acc, [name]: publicURL }),
    {}
  );

  return (
    <LoadableSimpleReactLightbox>
      <section
        className={styles.reviews}
        id="reviews"
        // data-anim-aos="fade"
        // data-aos-anchor-placement="top-bottom"
      >
        <div className="container">
          <h2
            className="c-section-title"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <LoadableSRLWrapperCustom className="relative z-index-2 mt-6">
            <div className="row">
              {data.videoReviewIds.length > 0 &&
                data.videoReviewIds.map((videoSrc, index) => (
                  <div
                    className="col-12 col-sm-6 col-md-4 mt-4"
                    key={`review-video-${index}`}
                  >
                    <YtPlayer
                      src={videoSrc}
                      thumbUrl={imageDataMapByName[videoSrc]}
                      variant="right"
                    />
                  </div>
                ))}
            </div>
          </LoadableSRLWrapperCustom>
        </div>
      </section>
    </LoadableSimpleReactLightbox>
  );
};

export default ReviewsSection;
