import BgShape from '@/assets/img/ReviewsSection/bg-shape.svg';

const data = {
  title: 'Отзывы',
  bgShapeSrc: BgShape,
  videoReviewIds: [
    'mEevizsgktw',
    'nLnqg6JrQ6A',
    'gxUq8zl40bs',
    'YSAYx4aS1m8',
    'gaAnzm3j5NU',
    'm-Wyx1Mf05c',
    'fNwMgd54mWs',
    'XG1ojRBP_JA',
    'llVbky3Xe_k',
    'joe3aTEO6G4',
    'Bnw3Nq03PZc',
    'vBqGUqKX7QQ',
  ],
};

export default data;
