const data = {
  sectionTitle: 'Книги',
  sec1: {
    initials: 'Андреас Тиссен',
    title: 'Сделай невозможное возможным',
    desc:
      'Вместе мы осуществим увлекательное путешествие в твой внутренний мир и извлечем оттуда самые ценные «бриллианты», чтобы впоследствии использовать их в новой, осознанной жизни.',
    action1Text: 'Перейти к покупке RU',
    action1Link:
      'https://christophor.ru/books/khristianskaya-zhizn/sdelay-nevozmozhnoe-vozmozhnym/',
    action2Text: 'Перейти к покупке UA',
    action2Link: 'https://brightbooks.com.ua/sdelay-nevozmozhnoe-vozmozhnym',
  },
  sec2: {
    title: 'Встань! Двигай задом! Живи!',
    desc:
      'В этой книге я расскажу Тебе, как и для чего нужно встать, поднять зад и жить полноценной жизнью. Каждая из представленных 15 систем борьбы с ленью станет вспомогательным элементом для твоего интенсивного личностного роста. Наберись терпения и начни практиковать. Совсем скоро Ты увидишь — #Ждун навсегда лишится сил и уйдет в далекие глубины подсознания отдыхать на любимом диванчике!',
    action1Text: 'Перейти к покупке',
    action1Link: 'https://brightbooks.com.ua/vstan-dvigay-zadom-zhivi',
  },
};

export default data;
