// extracted by mini-css-extract-plugin
export const topics = "TopicsSection-module--topics--Muudu";
export const shapes = "TopicsSection-module--shapes--smsU6";
export const bgShape = "TopicsSection-module--bgShape--16T11";
export const line1 = "TopicsSection-module--line1--3aJbW";
export const line2 = "TopicsSection-module--line2--3tqcw";
export const shape1 = "TopicsSection-module--shape1--39yFy";
export const shape2 = "TopicsSection-module--shape2--23eXy";
export const panel = "TopicsSection-module--panel--1LLVV";
export const header = "TopicsSection-module--header--fnn9H";
export const headerAction = "TopicsSection-module--headerAction--1hwal";
export const item = "TopicsSection-module--item--3F8bs";
export const itemCount = "TopicsSection-module--itemCount--2bjRH";
export const itemText = "TopicsSection-module--itemText--1OjYj";
export const frontManArea = "TopicsSection-module--frontManArea--fXCDb";
export const frontMan = "TopicsSection-module--frontMan--3wjFN";
export const circle = "TopicsSection-module--circle--3FvCV";