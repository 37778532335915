// extracted by mini-css-extract-plugin
export const contacts = "ContactsSection-module--contacts--sUSNA";
export const shapes = "ContactsSection-module--shapes--fQu73";
export const shape1 = "ContactsSection-module--shape1--363J_";
export const shape2 = "ContactsSection-module--shape2--3IHE2";
export const line1 = "ContactsSection-module--line1--3XjbB";
export const line2 = "ContactsSection-module--line2--1wqUp";
export const panel = "ContactsSection-module--panel--3f4my";
export const content = "ContactsSection-module--content--3JK7Z";
export const socials = "ContactsSection-module--socials--3XI5V";
export const rest = "ContactsSection-module--rest--3L1Dj";
export const social = "ContactsSection-module--social--1wlOi";
export const socialIcon = "ContactsSection-module--socialIcon--Vwqoi";
export const socialValues = "ContactsSection-module--socialValues--CisXQ";
export const socialValue = "ContactsSection-module--socialValue--RVZyq";
export const frontManArea = "ContactsSection-module--frontManArea--rblrQ";
export const qTitle = "ContactsSection-module--qTitle--1tCHX";
export const qAnswer = "ContactsSection-module--qAnswer--2dRrQ";
export const actionBtn = "ContactsSection-module--actionBtn--3qKJY";