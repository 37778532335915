/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import cx from 'clsx';
import * as styles from './PulsatePlayBtn.module.scss';

const PulsatePlayBtn = ({
  src = '5iqduvIg5ew',
  thumbUrl: thumbnailUrl,
  className,
  ...props
}) => {
  const thumbUrl =
    thumbnailUrl ||
    // `https://i.ytimg.com/vi/${src}/maxresdefault.jpg` ||
    `https://i.ytimg.com/vi/${src}/hqdefault.jpg`;
  const imageRef = useRef(null);
  const onContentClick = (e) => {
    e.preventDefault();
    imageRef.current.click();
  };
  return (
    <a
      srl_video_thumbnail={thumbUrl}
      srl_video_controls="false"
      href={`https://www.youtube.com/watch?v=${src}`}
      className={cx(styles.pulsateBtn, styles.bgColor, className)}
      {...props}
    >
      {/** NOTE: Until be fixed https://github.com/michelecocuccio/simple-react-lightbox/issues/143 */}
      <img
        ref={imageRef}
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        height="100%"
        alt=""
        style={{ display: 'none' }}
      />
      <div
        onClick={onContentClick}
        className={cx(styles.bg, styles.bgColor)}
        role="button"
        tabIndex="-1"
      />
      <div
        onClick={onContentClick}
        className={styles.content}
        role="button"
        tabIndex="-1"
      >
        <span className={styles.triangle}></span>
      </div>
      <div onClick={onContentClick} role="button" tabIndex="-1">
        <span
          className={cx(styles.ripple, styles.ripple1, styles.bgColor)}
        ></span>
        <span
          className={cx(styles.ripple, styles.ripple2, styles.bgColor)}
        ></span>
        <span
          className={cx(styles.ripple, styles.ripple3, styles.bgColor)}
        ></span>
      </div>
    </a>
  );
};

export default PulsatePlayBtn;
