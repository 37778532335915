import deepMerge from 'deepmerge';

// Separate title letter animation
export const getTitleSplitted = (conf = {}) =>
  deepMerge(
    {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: {
          when: 'beforeChildren',
          staggerChildren: 0.1,
        },
      },
    },
    conf
  );
// Separate title letter animation
export const getTitleSplittedLetter = (conf = {}) =>
  deepMerge(
    {
      hidden: {
        y: '100%',
        opacity: 0.4,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: [0, 0, 0.2, 1],
          // ease: [0.17, 0.67, 0.83, 0.67],
        },
      },
    },
    conf
  );

export const getBaseOpacity = (conf = {}) =>
  deepMerge(
    {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: {
          duration: 0.6,
        },
      },
    },
    conf
  );
