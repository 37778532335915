import React from 'react';
import cx from 'clsx';
import data from './data';
import * as styles from './QualitySection.module.scss';

const isLastInOdd = (index) =>
  data.items.length % 2 === 1 && index === data.items.length - 1;

const QualitySection = () => {
  return (
    <section className={styles.quality} id="quality">
      <img className={styles.bgShape} src={data.bgShapeSrc} alt="" />
      <div
        className="container relative d-flex"
        data-anim-aos="fade"
        data-aos-anchor="#quality"
      >
        <div className="row mt-n6">
          {data.items.map((item, index) => (
            <div
              className={cx(
                'col-12 col-lg-4 mt-6',
                !isLastInOdd(index) && 'col-md-6'
              )}
              key={`quality-item-${index}`}
            >
              <div className={styles.item}>
                <img className={styles.itemIcon} src={data.checkSrc} alt="" />
                <h3
                  className={styles.itemTitle}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <div
                  className={styles.itemDesc}
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default QualitySection;
