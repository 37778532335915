import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, useAnimation } from 'framer-motion';
import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';
import cx from 'clsx';
import PulsatePlayBtn from '@/components/PulsatePlayBtn/PulsatePlayBtn';
import {
  getTitleSplitted,
  getTitleSplittedLetter,
  getBaseOpacity,
} from '@/utils/framer-animations';

import data from './data';
import * as styles from './AboutSection.module.scss';

const LoadableSimpleReactLightbox = loadable(
  () => import('@/components/Lightbox'),
  {
    fallback: <div>Загрузка...</div>,
  }
);
const LoadableSRLWrapperCustom = loadable(
  () => import('@/components/Lightbox'),
  {
    resolveComponent: (components) => components.SRLWrapperCustom,
    fallback: <div>Загрузка...</div>,
  }
);

const animVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
    when: 'beforeChildren',
  },
};

const QuoteWrapper = ({ ...props }) => {
  return (
    <div className={styles.quoteWrapper} {...props}>
      <img
        className={styles.lq}
        src={data.lqSrc}
        alt=""
        width="46"
        height="46"
      />
      <img
        className={styles.rq}
        src={data.rqSrc}
        alt=""
        width="46"
        height="46"
      />
      <blockquote
        className={styles.quote}
        dangerouslySetInnerHTML={{ __html: data.quote }}
      />
    </div>
  );
};

const AboutSection = () => {
  const controls = useAnimation();

  const [ref, inView] = useInView({
    threshold: [0.1],
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section className={styles.about} id="about" ref={ref}>
      <div className={styles.customShapes}>
        <img className={styles.customShape1} src={data.customShapeSrc} alt="" />
      </div>
      <div className={styles.innerArea}>
        <div className="container">
          <div className={styles.inner}>
            <motion.div className={styles.media}>
              <div className={styles.manArea}>
                <div className={styles.shape1}>
                  <div
                    data-anim-aos="fade-left"
                    data-aos-easing="ease-out"
                    data-aos-delay="100"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <img src={data.shape1Src} alt="" width="186" height="186" />
                  </div>
                </div>
                <div className={styles.shape2}>
                  <div
                    data-anim-aos="fade-up"
                    data-aos-easing="ease-out"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <img src={data.shape1Src} alt="" width="116" height="116" />
                  </div>
                </div>
                <div
                  className={cx(
                    'd-inline-block c-anim-zoom-in-up',
                    styles.manWrapper
                  )}
                  data-anim-aos
                  data-aos-anchor="#about"
                >
                  <StaticImage
                    className={styles.man}
                    src="../../../assets/img/AboutSection/man.png"
                    placeholder="blurred"
                    alt=""
                  />

                  <LoadableSimpleReactLightbox>
                    <LoadableSRLWrapperCustom
                      options={{
                        thumbnails: {
                          showThumbnails: false,
                        },
                      }}
                    >
                      <PulsatePlayBtn
                        src={data.videoSrc}
                        className={styles.pulsateBtn}
                      />
                    </LoadableSRLWrapperCustom>
                  </LoadableSimpleReactLightbox>
                </div>
              </div>
            </motion.div>
            <motion.div
              className={styles.meta}
              animate={controls}
              initial="hidden"
              variants={animVariants}
            >
              <motion.h2
                className={cx('c-section-title', styles.title)}
                initial="hidden"
                animate={controls}
                variants={getTitleSplitted({
                  visible: {
                    transition: {
                      staggerChildren: 0.03,
                    },
                  },
                })}
              >
                {data.titleLines.map((titleLine, tIndex) => (
                  <div
                    className="d-flex text-wrap"
                    key={`about-title-line-${tIndex}`}
                  >
                    {titleLine.split('').map((char, index) => (
                      <div
                        className="ov-hidden"
                        key={`title-line-${tIndex}-char-${char}-${index}`}
                      >
                        <motion.div
                          variants={getTitleSplittedLetter({
                            visible: {
                              transition: {
                                duration: 0.9,
                                type: 'spring',
                                stiffness: 100,
                              },
                            },
                          })}
                        >
                          {char}
                        </motion.div>
                      </div>
                    ))}
                  </div>
                ))}
              </motion.h2>
              <motion.article
                className={cx('article', styles.desc)}
                variants={getTitleSplitted({
                  visible: {
                    transition: {
                      delayChildren: 0,
                      staggerChildren: 0.15,
                      delay: 1,
                    },
                  },
                })}
                initial="hidden"
                animate={controls}
              >
                {data.descParagraphs.map((p, index) => (
                  <motion.p
                    key={`${p}-${index}`}
                    variants={getBaseOpacity()}
                    dangerouslySetInnerHTML={{ __html: p }}
                  />
                ))}
              </motion.article>

              <div className="d-none d-lg-block">
                <QuoteWrapper
                  data-anim-aos="fade"
                  data-aos-delay="300"
                  data-aos-anchor-placement="top-bottom"
                />
              </div>
            </motion.div>
          </div>
          <div className="d-lg-none">
            <QuoteWrapper
              data-anim-aos="fade"
              data-aos-delay="300"
              data-aos-anchor-placement="top-bottom"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
