import Line1 from '@/assets/img/ContactsSection/line1.svg';
import Shape1 from '@/assets/img/shape-square-white.svg';
import Instagram from '@/assets/img/icons/socials/instagram.svg';
import Facebook from '@/assets/img/icons/socials/facebook.svg';
import Telegram from '@/assets/img/icons/socials/telegram.svg';
import Email from '@/assets/img/icons/socials/email.svg';

const data = {
  title: 'Контакты',
  socials: {
    instagram: {
      icon: Instagram,
      values: ['@andreastissen__', '@andreas.tissen_official'],
    },
    facebook: {
      icon: Facebook,
      values: ['@navigatorofsuccess', '@deinerfolgsnavigator'],
    },
    telegram: {
      icon: Telegram,
      values: ['@andreastissen_successnavigator', '@DeinErfolgsnavigator'],
    },
    vk: {
      icon: Vk,
      values: ['@andreas_tissen'],
    },
    email: {
      icon: Email,
      values: ['andreastissen.psy@gmail.com'],
    },
  },
  line1Src: Line1,
  shape1Src: Shape1,
  qTitle: 'Ты готов(а) к <span>новому</span> уровню?',
  qAnswer: 'ДА! Я хочу больше получить от жизни…',
  actionBtnText: 'Получить больше',
};

export default data;
