import Line1 from '@/assets/img/TopicsSection/line1.svg';
import Shape1 from '@/assets/img/shape-square-white.svg';
import BgShape from '@/assets/img/bg-shape-down.svg';

const data = {
  title: 'Темы тренингов',
  orderBtnText: 'Узнать больше',
  items: [
    // 'Избав­ле­ние от страхов и раскры­тие потен­ци­а­ла личности',
    // 'Внутрен­няя транс­фор­ма­ция и созда­ние жизни мечты',
    'Как найти своё призва­ние и реали­зо­вать свою жизнен­ную миссию',
    'Созда­ние лично­го бренда',
    'Избав­ле­ние от деструк­тив­ных убежде­ний и ограни­че­ний, прора­бот­ка блоков.',
    'Укреп­ле­ние отноше­ний и принци­пы успеха семей­ной жизни',
    'Мастер­ство комму­ни­ка­ции и ведение переговоров',
    'Избав­ле­ние от страхов и раскры­тие потен­ци­а­ла личности',
    'Как найти своё призва­ние и реали­зо­вать свою жизнен­ную миссию',
    // 'Внутрен­няя транс­фор­ма­ция и созда­ние жизни мечты',
  ],
  line1Src: Line1,
  shape1Src: Shape1,
  bgShapeSrc: BgShape,
};

export default data;
