import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import {
  getTitleSplitted,
  getTitleSplittedLetter,
  getBaseOpacity,
} from '@/utils/framer-animations';
import data from './data';
import * as styles from './IntroSection.module.scss';

const IntroSection = () => {
  // Circle first (1s)
  const manCircleVariants = {
    hidden: {
      opacity: 0,
      x: '-50%',
      y: 34,
      scale: 0.85,
      rotate: 45,
    },
    visible: {
      opacity: 1,
      x: '-50%',
      y: 0,
      scale: 1,
      rotate: 0,
      transition: {
        duration: 1,
      },
    },
  };
  // Front man after circle (2s)
  const manImgVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      delay: 1,
      duration: 1,
    },
  };

  const controls = useAnimation();

  return (
    <section className={styles.intro} id="intro">
      <div className={cx('c-blur-panel-backdrop', styles.back)}>
        <div className={styles.customShapes}>
          <img className={styles.customShape1} src={data.shape1Src} alt="" />
          <img className={styles.customShape2} src={data.shape2Src} alt="" />
        </div>
        <StaticImage
          className={styles.backdropMan}
          placeholder="none"
          src="../../../assets/img/IntroSection/man.png"
          alt=""
        />
      </div>
      <div className={cx(styles.front, 'c-blur-panel-front is-light')}>
        <div className={styles.frontManArea}>
          <motion.div
            className={styles.circle}
            variants={manCircleVariants}
            initial="hidden"
            animate="visible"
          >
            <img className={styles.line1} src={data.shapeBrSrc} alt="" />
            <img className={styles.line2} src={data.shapeBrSrc} alt="" />
          </motion.div>
          <StaticImage
            className={styles.frontMan}
            placeholder="none"
            src="../../../assets/img/IntroSection/man.png"
            alt=""
            variants={manImgVariants}
            initial="hidden"
            animate={controls}
          />
        </div>
        <div className={cx('container', styles.meta)}>
          <motion.h1
            className={styles.title}
            variants={getTitleSplitted()}
            initial="hidden"
            animate="visible"
          >
            {data.titleLines.map((titleLine, tIndex) => (
              <div className="d-flex" key={`title-line-${tIndex}`}>
                {titleLine.split('').map((char, index) => (
                  <div className="ov-hidden" key={`${char}-${index}`}>
                    <motion.div
                      key={`${char}-${index}`}
                      variants={getTitleSplittedLetter()}
                    >
                      {char}
                    </motion.div>
                  </div>
                ))}
              </div>
            ))}
          </motion.h1>
          <div className={styles.desc}>
            <motion.article
              variants={getTitleSplitted({
                visible: {
                  transition: {
                    delayChildren: 0.6,
                    delay: 1,
                  },
                },
              })}
              initial="hidden"
              animate="visible"
            >
              {data.desc.split(' ').map((word, index) => (
                <motion.span
                  key={`${word}-${index}`}
                  variants={getBaseOpacity()}
                >
                  {word + (index !== data.desc.length - 1 ? ' ' : '')}
                </motion.span>
              ))}
            </motion.article>
          </div>
        </div>
      </div>
    </section>
  );
};

IntroSection.propTypes = {};

IntroSection.defaultProps = {};

export default IntroSection;
