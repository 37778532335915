// extracted by mini-css-extract-plugin
export const news = "NewsSection-module--news--38SKZ";
export const bgShape = "NewsSection-module--bgShape--2aXzI";
export const line1 = "NewsSection-module--line1--1lw4t";
export const line2 = "NewsSection-module--line2--3Pto4";
export const shape1 = "NewsSection-module--shape1--3JeO0";
export const itemMedia = "NewsSection-module--itemMedia--PyXip";
export const itemImgWrapper = "NewsSection-module--itemImgWrapper--1n1wB";
export const itemMeta = "NewsSection-module--itemMeta--3Mzcb";
export const itemTitle = "NewsSection-module--itemTitle--tJo7h";
export const itemExcerpt = "NewsSection-module--itemExcerpt--3Tuck";
export const itemLink = "NewsSection-module--itemLink--2fULK";