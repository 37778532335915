import React from 'react';
import cx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
import useModal from '@/hooks/useModal';
import { modals } from '@/constants/ctx';
import { Button } from '@/components/inputs';
import Mailto from '@/components/mailto';
import data from './data';
import * as styles from './ContactsSection.module.scss';

const getSocialLink = (social, value) => {
  switch (social) {
    case 'instagram':
      return `https://www.instagram.com/${value.replace('@', '')}/`;
    case 'facebook':
      return `https://www.facebook.com/${value.replace('@', '')}/`;
    case 'telegram':
      return `https://t.me/${value.replace('@', '')}`;
    default:
      return value;
  }
};

const ContactsSection = () => {
  const { open: openOrderModal } = useModal(modals.orderModal);
  return (
    <section className={styles.contacts} id="contacts">
      <div className={cx('container', styles.shapes)}>
        <img
          className={styles.shape1}
          src={data.shape1Src}
          width="145"
          alt=""
        />
        <img className={styles.shape2} src={data.shape1Src} alt="" />
        <img className={styles.line1} src={data.line1Src} alt="" />
        <img className={styles.line2} src={data.line1Src} width="196" alt="" />
      </div>
      <div className={cx('c-blur-panel-front brs', styles.panel)}>
        <div className="container relative z-index-2">
          <h2
            className="c-section-title"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <div className={styles.content}>
            <div className={styles.socials}>
              {Object.keys(data.socials).map((k) => (
                <div className={styles.social} key={`contacts-social-${k}`}>
                  <img
                    src={data.socials[k].icon}
                    alt={k}
                    className={styles.socialIcon}
                  />
                  <div className={styles.socialValues}>
                    {data.socials[k].values.map((v, vIdx) => {
                      if (k === 'email') {
                        return (
                          <Mailto
                            email={v}
                            obfuscate={true}
                            className={styles.socialValue}
                            key={`contacts-social-value-${vIdx}`}
                          >
                            {v}
                          </Mailto>
                        );
                      }

                      return (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <a
                          role="button"
                          className={styles.socialValue}
                          href={getSocialLink(k, v)}
                          target="_blank"
                          key={`contacts-social-value-${vIdx}`}
                          rel="noreferrer"
                          dangerouslySetInnerHTML={{ __html: v }}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.rest}>
              <div className={styles.frontManArea}>
                <StaticImage
                  className={styles.frontMan}
                  placeholder="tracedSVG"
                  src="../../../assets/img/ContactsSection/man.png"
                  alt=""
                />
              </div>
              <div className={styles.meta}>
                <h3
                  className={styles.qTitle}
                  dangerouslySetInnerHTML={{ __html: data.qTitle }}
                />
                <div
                  className={styles.qAnswer}
                  dangerouslySetInnerHTML={{ __html: data.qAnswer }}
                />
                <Button
                  theme="primary"
                  className={styles.actionBtn}
                  onClick={openOrderModal}
                >
                  {data.actionBtnText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;
