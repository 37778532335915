import BgShape from '@/assets/img/ServicesSection/bg-shape.svg';
import Shape1 from '@/assets/img/ServicesSection/shape1.svg';
import Ic1 from '@/assets/img/ServicesSection/1.svg';
import Ic2 from '@/assets/img/ServicesSection/2.svg';
import Ic3 from '@/assets/img/ServicesSection/3.svg';
import Ic4 from '@/assets/img/ServicesSection/4.svg';
import Ic5 from '@/assets/img/ServicesSection/5.svg';
import Ic6 from '@/assets/img/ServicesSection/6.svg';

const data = {
  bgShapeSrc: BgShape,
  shape1Src: Shape1,
  title: 'Чему он может научить вас',
  // titleLines: ['Чему он может', 'научить вас'],
  items: [
    {
      text: 'Проведение корпоративных тренингов',
      icon: Ic1,
    },
    {
      text: 'Системное становление личности и тренировка осознанности',
      icon: Ic2,
    },
    {
      text: 'Онлайн марафоны и мотивационные программы',
      icon: Ic3,
    },
    {
      text: 'Обучение коучингу',
      icon: Ic4,
    },
    {
      text: 'Личные коуч-сессии',
      icon: Ic5,
    },
    {
      text: 'Техники, видеоуроки, упражнения, мотивационные интенсивы.',
      icon: Ic6,
    },
  ],
};

export default data;
