import Check from '@/assets/img/QualitySection/check.svg';
import BgShape from '@/assets/img/bg-shape-down.svg';

const data = {
  checkSrc: Check,
  bgShapeSrc: BgShape,
  items: [
    {
      title: 'Мой<br>жизненный<br>опыт',
      desc:
        'и истории трансформации моих клиентов говорят о том, что Ты можешь достичь гораздо большего, чем ты привык думать.',
    },
    {
      title: 'Мое призвание',
      desc:
        'сопровождать Тебя на пути к осознанию Твоей уникальности, раскрытию Твоего потенциала и обретению реализации в каждой сфере жизни.',
    },
    {
      title: 'Я даю тебе <span class="text-nowrap">100%-ную</span> гарантию',
      desc:
        'что благодаря обучению и личной трансформации, в Твоей жизни произойдут глубинные изменения и уже скоро Ты начнёшь менять мир к лучшему!',
    },
  ],
};

export default data;
