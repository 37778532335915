// extracted by mini-css-extract-plugin
export const books = "BooksSection-module--books--1by28";
export const imgAreaWrap = "BooksSection-module--imgAreaWrap--2s36y";
export const imgArea = "BooksSection-module--imgArea--2V43Q";
export const img = "BooksSection-module--img--12zzc";
export const imgLeft = "BooksSection-module--imgLeft--253Qp";
export const imgRight = "BooksSection-module--imgRight--3s4Qg";
export const circle = "BooksSection-module--circle--2BZ3v";
export const meta = "BooksSection-module--meta--3mQtq";
export const initials = "BooksSection-module--initials--mim2x";
export const title2 = "BooksSection-module--title2--2H_U6";
export const desc = "BooksSection-module--desc--2LT_Y";
export const actions = "BooksSection-module--actions--6jZvn";
export const action2 = "BooksSection-module--action2--jzPZ-";