import React, { Fragment } from 'react';
import cx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@/components/inputs';
import useModal from '@/hooks/useModal';
import { modals } from '@/constants/ctx';

import data from './data';
import * as styles from './TopicsSection.module.scss';

const TopicsSection = () => {
  const { open: openOrderModal } = useModal(modals.orderModal);
  return (
    <section className={styles.topics}>
      <img className={styles.bgShape} src={data.bgShapeSrc} alt="" />
      <div
        className={cx('container', styles.shapes)}
        data-anim-aos="zoom-in"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay="600"
      >
        <img className={styles.shape1} src={data.shape1Src} alt="" />
        <img className={styles.shape2} src={data.shape1Src} alt="" />
        <img className={styles.line1} src={data.line1Src} alt="" />
        <img className={styles.line2} src={data.line1Src} alt="" />
      </div>
      <div className={cx('c-blur-panel-front brs', styles.panel)}>
        <div className="container relative z-index-2">
          <div className={styles.header}>
            <h2
              className="c-section-title"
              dangerouslySetInnerHTML={{ __html: data.title }}
              data-anim-aos="zoom-out-down"
              data-aos-easing="ease-out-back"
            />
            <Button
              theme="primary"
              data-anim-aos="zoom-out-down"
              data-aos-easing="ease-out-back"
              data-aos-delay="100"
              className={styles.headerAction}
              onClick={openOrderModal}
            >
              {data.orderBtnText}
            </Button>
          </div>
          <div className="mt-6 mt-md-10 mt-lg-12">
            <div className="row mt-n6 mt-md-n8 mt-lg-n10 mt-xl-n11">
              {data.items.map((item, index) => {
                var n = index + 1;

                return (
                  <Fragment key={`topic-item-${index}`}>
                    <div
                      className={`col-12 col-md-6 col-lg-4 mt-6 mt-md-8 ${
                        n >= 4
                          ? 'mt-lg-11 mt-xl-17'
                          : 'mt-lg-10 mt-xl-11'
                      }`}
                    >
                      <div
                        className={cx(styles.item, n>=4 && 'pr-xl-3')}
                        data-anim-aos="zoom-out-down"
                        data-aos-easing="ease-out-back"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-delay={((n > 5 ? n + 1 : n) % 3) * 100}
                      >
                        <div className={styles.itemCount}>{n}</div>
                        <div
                          className={styles.itemText}
                          dangerouslySetInnerHTML={{ __html: item }}
                        />
                      </div>
                    </div>
                    {n === 5 && (
                      <div className="d-none d-lg-block col-lg-4"></div>
                    )}
                  </Fragment>
                );
              })}
              <div className="col-12 mt-6 mt-md-8 col-lg-4 mt-lg-10 mt-xl-11 text-center text-lg-left">
                <div className={styles.frontManArea}>
                  <div className={styles.circle}></div>
                  <StaticImage
                    className={styles.frontMan}
                    src="../../../assets/img/TopicsSection/man.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopicsSection;
