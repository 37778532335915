import React from 'react';
import cx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '@/components/inputs';
import data from './data';
import * as styles from './BooksSection.module.scss';

const BooksSection = () => {
  return (
    <section className={styles.books} id="books">
      <div className="container">
        <h2
          className="c-section-title text-center text-lg-left"
          dangerouslySetInnerHTML={{ __html: data.sectionTitle }}
        />
        <div className="mt-6 mt-md-8 mt-lg-10">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className={styles.imgAreaWrap} data-anim-aos="fade">
                <div className={styles.imgArea}>
                  <div className={styles.circle}></div>
                  <StaticImage
                    className={styles.imgLeft}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book.png"
                    alt=""
                  />
                  <StaticImage
                    className={styles.imgRight}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book.png"
                    alt=""
                  />
                  <StaticImage
                    className={styles.img}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-5"
              data-anim-aos="fade"
              data-aos-anchor="#books"
            >
              <div className={styles.meta}>
                <div className={styles.initials}>{data.sec1.initials}</div>
                <h3
                  className={styles.title2}
                  dangerouslySetInnerHTML={{ __html: data.sec1.title }}
                />
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{ __html: data.sec1.desc }}
                />
                <div className={styles.actions}>
                  <Button
                    tag="a"
                    href={data.sec1.action1Link}
                    theme="primary"
                    className={styles.action1}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    {data.sec1.action1Text}
                  </Button>
                  <a
                    href={data.sec1.action2Link}
                    className={styles.action2}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    {data.sec1.action2Text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 mt-md-8 mt-lg-10">
          <div className="row f-lg-rr">
            <div className="col-12 col-lg-7">
              <div className={styles.imgAreaWrap} data-anim-aos="fade">
                <div className={styles.imgArea}>
                  <div className={styles.circle}></div>
                  <StaticImage
                    className={styles.imgLeft}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book2.png"
                    alt=""
                  />
                  <StaticImage
                    className={styles.imgRight}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book2.png"
                    alt=""
                  />
                  <StaticImage
                    className={styles.img}
                    placeholder="blurred"
                    src="../../../assets/img/BooksSection/book2.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-5"
              data-anim-aos="fade"
              data-aos-anchor="#books"
            >
              <div className={styles.meta}>
                <h3
                  className={styles.title2}
                  dangerouslySetInnerHTML={{ __html: data.sec2.title }}
                />
                <div
                  className={cx(styles.desc, 'mt-lg-7')}
                  dangerouslySetInnerHTML={{ __html: data.sec2.desc }}
                />
                <div className={styles.actions}>
                  <Button
                    tag="a"
                    href={data.sec2.action1Link}
                    theme="primary"
                    className={styles.action1}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    {data.sec2.action1Text}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BooksSection;
