import React from 'react';
import cx from 'clsx';
import data from './data';

import * as styles from './ServicesSection.module.scss';

const ServicesSection = () => {
  return (
    <section className={styles.services} id="services">
      <img className={styles.bgShape} src={data.bgShapeSrc} alt="" />
      <div className="container relative z-index-2">
        <div className="row jc-c">
          <div className="col-12 col-lg-10">
            <h2
              className={cx('c-section-title', styles.title)}
              dangerouslySetInnerHTML={{ __html: data.title }}
              data-anim-aos="fade-up"
              data-aos-anchor="#services"
            />
            <div className="mt-6 mt-md-8">
              <div
                id="services-items"
                className="row mt-n6 mt-md-n8 mt-lg-n10"
                data-anim-aos="fade"
                data-aos-anchor="#services"
              >
                {data.items.map((item, index) => (
                  <div
                    className="col-12 col-sm-6 mt-6 mt-md-8 mt-lg-10"
                    key={`service-${index}`}
                    data-anim-aos="fade-up"
                    data-aos-easing="ease-out-back"
                    data-aos-delay={100 + 100 * index}
                    data-aos-anchor="#services-items"
                  >
                    <div className={styles.item}>
                      <div className={styles.itemIconArea}>
                        <img
                          className={styles.itemIcon}
                          src={item.icon}
                          alt=""
                          width="70"
                          height="70"
                        />
                      </div>
                      <div
                        className={styles.itemMeta}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.shape1Spacer}>
              <div
                className={styles.shape1}
                data-anim-aos="zoom-out-down"
                data-aos-anchor="#services-items"
                data-aos-delay="1000"
              >
                <img src={data.shape1Src} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
